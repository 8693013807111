<template>
  <v-main >
    <router-view></router-view>
  </v-main>
</template>

<script>
// import Footer from './Footer'

export default {
  name: "Layout",
  components: {
  },
};
</script>

<style scoped>

</style>
