<template>
    <v-dialog 
        v-model="showAddV2" 
        :overlay=false 
        persistent
        fullscreen 
        hide-overlay 
        transition="dialog-bottom-transition"
    >   
        <v-card>
            <v-toolbar
                dark
                color="orange"
            >                
                <v-toolbar-title>USACH</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn
                        icon
                        dark
                        @click="openWarning"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <h1 class="mb-0">Agrega tu denuncia</h1>
            <div class="overline mb-4">Siga los siguientes pasos para ingresar una denuncia:</div>
            <v-stepper 
                v-model="curr" 
                color="green" 
                height="100%"
            >
                <v-stepper-header class="overflow-x-auto">
                    <v-stepper-step 
                        v-for="(step,n) in steps"
                        :key="n"
                        :complete="stepComplete(n+1)"
                        :step="n+1"
                        :editable="stepComplete(n+1)"
                        :color="stepStatus(n+1)"
                    >
                            {{ step.name }}
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items >
                    <v-stepper-content step="1">
                        <v-row>
                            <v-col cols="12" offset-sm="5" offset-md="5"  
                                style="height: 80 px;">
                                <v-spacer></v-spacer>
                                <v-btn 
                                    align="right"
                                    color="primary"
                                    @click="goForward()"
                                >
                                    Continuar <v-icon color="gray">mdi-menu-right</v-icon> 
                                </v-btn>

                            </v-col>
                            <v-col cols="12" sm="12" md="12" >
                                <SearchAddComplainant :complainants="complainants"/>
                                <Accordion :competitors="complainants" />    
                            </v-col>
                        </v-row>                      
                                
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-row>
                            <v-col cols="12" sm="2" md="2"  
                                style="height: 80 px;">
                                <v-btn 
                                    dark 
                                    color="orange" 
                                    @click="goBack()"
                                >
                                    <v-icon color="gray">mdi-menu-left</v-icon> Volver
                                </v-btn> 
                            </v-col>
                            <v-col cols="12" sm="2" md="2" 
                                offset-sm="8" offset-md="8"  
                                style="height: 80 px;">                                
                                <v-spacer></v-spacer>
                                <v-btn 
                                    align="right" 
                                    color="primary" 
                                    @click="goForward()"
                                >
                                    Continuar <v-icon color="gray">mdi-menu-right</v-icon> 
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" >
                                <SearchDenounced :denounceds="denounceds" />
                                <Accordion :competitors="denounceds" /> 
                            </v-col>
                        </v-row>                             
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-row>
                            <v-col cols="12" sm="2" md="2"  
                                style="height: 80 px;">
                                <v-btn 
                                    dark 
                                    color="orange" 
                                    @click="goBack()"
                                >
                                    <v-icon color="gray">mdi-menu-left</v-icon> Volver
                                </v-btn> 
                            </v-col>
                            <v-col cols="12" sm="2" md="2" 
                                offset-sm="8" offset-md="8"  
                                style="height: 80 px;">                                
                                <v-spacer></v-spacer>
                                <v-btn 
                                    align="right" 
                                    color="primary" 
                                    @click="goForward()"
                                >
                                    Continuar <v-icon color="gray">mdi-menu-right</v-icon> 
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" >
                                 <Detail :details="details" />
                            </v-col>
                        </v-row>
                                             
                    </v-stepper-content>

                    <v-stepper-content step="4">
                        <v-row>                           
                            <v-col cols="12" sm="2" md="2"  
                                style="height: 80 px;">
                                <v-btn 
                                    dark 
                                    color="orange" 
                                    @click="goBack()"
                                >
                                    <v-icon color="gray">mdi-menu-left</v-icon> Volver
                                </v-btn>     
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <div>
                                    <v-alert
                                        outlined
                                        type="warning"
                                        prominent
                                        border="left"
                                    >
                                        <h2> Revise todos los datos ingresados para confirmar la denuncia. </h2>
                                    </v-alert>
                                </div>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" >   
                                 <v-card
                                    class="mx-auto"
                                    color="blue lighten-5" 
                                    outlined
                                >
                                    <v-card-title class="text-h5">
                                        <!-- <h4 class="white--text text--lighten-2"> Querellante(s)</h4>. -->
                                        Querellante(s)
                                    </v-card-title>
                                    <Accordion :competitors="complainants" :disable="true"/>
                                </v-card>                             
                                 <v-card
                                 class="mx-auto"
                                    color="orange lighten-5"
                                    outlined
                                >
                                    <v-card-title class="text-h5">
                                        <!-- <h4 class="white--text text--lighten-2"> Denunciado(s)</h4>. -->
                                        Denunciado(s)
                                    </v-card-title>
                                    <Accordion :competitors="denounceds" :disable="true" />
                                </v-card>                                    
                            </v-col>    
                            <v-col cols="12" sm="12" md="12" >
                                <v-textarea 
                                    disabled
                                    class="pa-2"
                                    outlined 
                                    name="input-7-4" 
                                    label="Descripción de denuncia" 
                                    v-model="details.eventDescription" 
                                    required 
                                    :counter="500">
                                </v-textarea>
                            </v-col>                            
                            <v-col  cols="12" sm="12" md="12" >
                                <v-textarea 
                                    disabled
                                    class="pa-2"
                                    v-show = "details.protectiveMeasure"
                                    outlined 
                                    name="input-7-4" 
                                    label="Descripción medidas de protección" 
                                    v-model="details.protectiveMeasureDescription" 
                                    required 
                                    :counter="500">
                                </v-textarea>
                            </v-col>                        
                        </v-row>
                        
                        <v-spacer></v-spacer>
                        <v-btn 
                            dark
                            color="primary"
                            @click="saveComplaint"
                        >
                            Confirmar
                        </v-btn>
                        <v-btn 
                            text 
                            @click="openWarning"
                        >
                            Cancelar
                        </v-btn>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card>   
        
        <vs-dialog 
            @close="closeWarning" 
            v-model="dialogClose" 
            persistent
        >
        </vs-dialog>

        <v-snackbar
            v-model="snackbar"
            :timeout="2000"
            :value="true"            
            tile
            color="red accent-2"            
        >
            {{ text }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
    </v-dialog>   
</template>



<script>
import SearchAddComplainant from './Forms/Complainants/SearchAddComplainant.vue'
import {addComplaintsService} from '../../../../services/complaintsServices'
import Accordion from "./Forms/Accordion.vue"
import Detail from "./Forms/DetailComplaint.vue"
import SearchDenounced from './Forms/Denounceds/SearchDenounced.vue'
import { mapActions } from "vuex";
import {alertModal} from '../../../../functions/alertModal'

export default {
    components:{
        SearchAddComplainant,
        Accordion,
        Detail,
        SearchDenounced
    },
    props: {
        showAddV2: Boolean
    },
    data() {
      const vm = this;
      return{
        snackbar:false,
        text:"Error, complete el campo vacío!",
        editable: false,
        curr: 1,
        lastStep: 4,
        valid: false,
        stepForm: [],
        showSelectComplaints: false,
        steps: [
            {name: "Datos querellante", rules: [v => !!v || "Required."], valid: true},
            {name: "Datos denunciado", rules: [v=> !!v || "Required."], valid: true},
            // {name: "Descripción demanda", rules: [v => (v && v.length >= 4) || "Enter at least 4 characters."], valid: true},
            {name: "Descripción demanda", rules: [v=> !!v || "Required."], valid: true},
            {name: "Finalizar"},
        ],
        handlers: {
            //closeSelect: vm.closeSelectComplainants,
            //dontClose: vm.closeWarning,
            close: vm.closeAddComplaint,
            closeSelect: vm.closeSelectComplainants,
            
            
        },
        dialogClose: false,
        complainants:[],
        denounceds:[],
        details:{
            eventDescription: "",
            complaintCreatedAt:  new Date().toISOString(),
            protectiveMeasure: false,
            protectiveMeasureDescription: ""
        }
      }
    },    
    async mounted() {
        await this.callActions();      
    },
    methods: {       
        ...mapActions('stament',['getStaments']),      
        ...mapActions('unit',['getUnits']),    
        async callActions(){
            try{
                await this.getStaments();
            }
            catch(error){
                console.log("getStamentsService: ",error)
            }
            try{
                await this.getUnits();
            }
            catch(error){
                console.log("getUnitsService: ", error)
            }            
        },
        closeWarning(){
            this.$emit('closeDetail');       
            //console.log("cerrar warning")
            this.dialogClose = false;
        },
        async openWarning(){
            alertModal({
                title:"¿Desea cerrar sin guardar?", 
                text: "Se perderan los cambios realizados.", 
                confirmButtonText: "Sí", 
                cancelButtonText: "Cancelar", 
                acceptFunction: this.closeAddComplaint, 
                showCancelButton: true,
                cancelFunction: () =>{console.log("cancelando")},
                icon: 'question'                 
            })
            
            //this.dialogClose = true;
        },
        closeAddComplaint(){
            this.curr=1;
            //this.closeWarning();
            this.$emit('closeAdd')
        },
        closeSelectComplainants(){
            this.showSelectComplaints =false;
        },
        async saveComplaint() {
            
            if(this.complainants.length==0 || this.denounceds==0 || this.details.eventDescription==""){
                this.text="Error, complete el campo vacío!";
                return this.snackbar = true;
            }
            else if(this.details.protectiveMeasure && this.details.protectiveMeasureDescription==""){
                this.text = "Error, describa medidas de protección!"
                return this.snackbar = true;
            }
            const participants = [...this.complainants, ...this.denounceds];
            var newcomplaint ={
                "participants": participants,
                "eventDescription": this.details.eventDescription,
                "protectiveMeasure": this.details.protectiveMeasure,
                "protectiveMeasureDescription": this.details.protectiveMeasureDescription
            }
            console.log("la denuncia ingresada es: ",newcomplaint)
            try {
                await addComplaintsService(newcomplaint);
            } catch (error) {
                console.log("Se da el error: ",error)
            }
            this.$emit('closeAdd')
            
        },
        stepComplete(step) {
            return this.curr > step
        },
        stepStatus(step) {
            return this.curr > step ? 'green' : 'blue'
        },
        validate(n) {
            this.steps[n].valid = false
            let v = this.$refs.stepForm[n].validate()
            if (v) {
                this.steps[n].valid = true
                // continue to next
                //this.curr = n+2
                this.goBack();
            }
        },
        goBack() {
              this.curr--;
              if (this.curr < 0) this.curr = 4
              this.editable=false;
        },
        goForward() {
            this.curr++;
            if (this.curr == 4){
                this.editable=true;
            }
            if (this.curr > 4){
                this.curr = 1
            } 
        },
    }
  }

</script>

<style>
    input:disabled {
        color: #000 !important;
    }    
</style>
<style>
    .flexcard {
    box-shadow: none !important;
    }
</style>
<style>
    .v-stepper {
        box-shadow: none !important;
    }
</style>>