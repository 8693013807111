import {loginService, logoutService} from '../../services/userServices';

export default{
    namespaced: true,
    state:{
        userState: {},
        isLogin: false
    },
    mutations:{
        setUserState(state, user){
            state.userState = user;
            state.isLogin = true;
        },
        cleanUserState(state){
            state.userState = {};
            state.isLogin = false;
        }
    },
    actions:{
        async loginUser({commit}, dataUser){
            try{
                const data = await loginService(dataUser);
                console.log(data);
                if(data.token){
                    commit("setUserState", data);
                }                
                return data;
            }
            catch(error){
                console.log(error);
                return error;
            }
            
        },
        async logoutUser({commit}){
            try {
                const data = await logoutService();
                console.log(data);
                commit("cleanUserState",);
            } catch (error) {
                console.log(error)
            }
            
        }
    }
}