<template>
    <vs-dialog v-if="show" v-model="show" width="800px" height="800px">
        <div class="con-content">
            <header>
                <span class="text-h5">Detalles de la denuncia</span>
            </header>
            
            <v-container>
                <v-row>
                    <v-col cols="12" sm="6" md="6" >
                        <v-text-field
                            label="Número de la causa"
                            v-model="complaint.complaint_id"                        
                            :disabled=true
                            
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" >
                        <v-text-field
                            label="Run querellante"
                            v-model="complaint.complainant_run"
                            disabled
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" >
                        <v-text-field
                            label="Nombre del querellante"
                            v-model="complaint.complainant_name"
                            disabled
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" >
                        <v-text-field
                            label="Estamento del querellante"
                            v-model="complaint.complainant_estament_type"
                            disabled
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" >
                        <v-text-field
                            label="Unidad  del querellante"
                            v-model="complaint.complainant_unit_name"
                            disabled
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" >
                        <v-text-field
                            label="Correo personal del querellante"
                            v-model="complaint.complainant_personal_email"
                            disabled
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" >
                        <v-text-field
                            label="Correo institucional querellante"
                            v-model="complaint.complainant_institutional_email"
                            disabled
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" >
                        <v-text-field
                            label="Nombre de denunciado"
                            v-model="complaint.denounced_name"
                            disabled
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" >
                        <v-text-field
                            label="Estamento del denunciado"
                            v-model="complaint.denounced_estament_type"
                            disabled
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" >
                        <v-text-field
                            label="Unidad  del denunciado"
                            v-model="complaint.denounced_estament_type"
                            disabled
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" >
                        <v-text-field
                            label="Correo institucional denunciado"
                            v-model="complaint.denounced_institutional_email"
                            disabled
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" >
                        <v-text-field
                            label="Descripción de denuncia"
                            v-model="complaint.event_description"
                            disabled
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" v-show= complaint.protective_measure >
                        <v-text-field
                            label="Medidas de protección"
                            v-model="complaint.protective_measure_description"
                            disabled
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" >
                        <v-text-field
                            label="Fecha de denuncia"
                            v-model="complaint.complaint_created_at"
                            disabled
                        ></v-text-field>
                    </v-col>
                    
                </v-row>
            </v-container>
        </div>
    </vs-dialog>
</template>


<script>

export default {
    props: {
        value: Boolean,
        complaintDetail: Object
    },
    data() {
      const vm = this;
      return{
        handlers: {
            close: vm.closeDetail,
        },
      }
    },
    methods: {
        closeDetail(){
            //this.$emit('closeDetail');       
            console.log("cerrar")
        },
    },
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        complaint: {
            get () {
                return this.complaintDetail
            },
            set (complaintDetail) {
                this.$emit('input', complaintDetail)
            }
        }
    },
}
</script>


<style>
    input:disabled {
        color: #000 !important;
    }
</style>