<template>
    <v-dialog 
        v-model="showValidate" 
        :overlay=false 
        persistent
        hide-overlay 
        width="500"
        transition="dialog-bottom-transition"
    > 
        <div>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card>
                    
                    <br>
                    <h3 class="text-h5 text-center">
                        <b>Datos denunciado</b>
                    </h3>

                    <v-card-text>
                        <v-list class="transparent">
                        <v-row>                           
                            <v-col cols="12" sm="12" md="12" > 
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-icon color="indigo">
                                            mdi-rename-box
                                        </v-icon>
                                    </v-list-item-icon>     
                                
                                    <v-list-item-content class="text-left">
                                        <v-list-item-subtitle>Nombre</v-list-item-subtitle>
                                        <v-list-item-title>{{denounced.name}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>  

                                <v-list-item>
                                    <v-list-item-icon>
                                    <v-icon color="indigo">
                                        mdi-email
                                    </v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content class="text-left">
                                        <v-list-item-subtitle>Correo institucional</v-list-item-subtitle>
                                        <v-list-item-title>{{denounced.institutionalEmail}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>      

                                <v-list-item>
                                    <v-list-item-icon>
                                    <v-icon color="indigo">
                                        mdi-email
                                    </v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content class="text-left">
                                        <v-list-item-subtitle>Correo personal</v-list-item-subtitle>
                                        <v-list-item-title>{{denounced.personalEmail}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-icon>
                                    <v-icon color="indigo">
                                        mdi-account
                                    </v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content class="text-left">
                                        <v-list-item-subtitle>Estamento</v-list-item-subtitle>
                                        <v-list-item-title v-if="denounced.stament">{{findStament(denounced.stament)}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-icon>
                                    <v-icon color="indigo">
                                        mdi-school
                                    </v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content class="text-left">
                                        <v-select
                                            class="pa-2"
                                            label="Unidad  del denunciado"
                                            v-model="denounced.unit"
                                            :items="this.unitsState"
                                            item-value="id"
                                            item-text="name" 
                                            :rules="unitRules"
                                            required                       
                                        ></v-select>
                                    </v-list-item-content>
                                </v-list-item>             
                            </v-col>

                        </v-row>
                        </v-list>
                    </v-card-text>

                    <v-card-actions>
                        <v-row>
                            <v-col cols="12" sm="6" md="6" >
                                <v-btn
                                    color="error"
                                    class="mr-4"
                                    @click="closeValidate"
                                    align="left"
                                >
                                    Cancelar
                                </v-btn>
                            </v-col>
                            
                            <v-col cols="12" sm="6" md="6" >
                                <v-btn
                                    color="success"
                                    class="mr-4"
                                    @click="saveDenounced"
                                    align="right"
                                >
                                <v-icon color="white" left>mdi-account-check</v-icon>
                                    Confirmar
                                </v-btn>
                            </v-col>                       
                        </v-row>
                    </v-card-actions>

                    <br>
                </v-card>
            </v-form>
        </div>
    </v-dialog>
</template>


<script>
import { mapState } from "vuex";

export default {
    props:{
        denounced: Object,
        denounceds: Array,
        showValidate: Boolean
    },
    data(){
        return{
            valid: true,
            unitRules: [v => !!v || 'Debe seleccionar una unidad'],
        }
    },
    methods:{
        closeValidate(){
            this.$emit('closeValidate');
        },
        findStament(id){
            //console.log(this.stamentsState)
            return this.stamentsState.find( stament => stament.id === id ).type;
        },
        findUnit(id){
            if (id != undefined ){
                return this.unitsState.find( unit => unit.id === id ).name;
            }               
        },
        saveDenounced(){          
            if(this.$refs.form.validate()){
                let competitor={
                    rut: this.denounced.run,
                    name: this.denounced.name, 
                    personalEmail: this.denounced.personalEmail, 
                    institutionalEmail: this.denounced.institutionalEmail,
                    estamentId: this.denounced.stament, 
                    unitId: this.denounced.unit, 
                    participantTypeId: 2
                }
                this.denounceds.push(competitor);
                this.$emit('saveAndCloseValidate');
            }              
        }
    },
    computed:{
        ...mapState('unit',['unitsState']),
        ...mapState('stament',['stamentsState']),
    },

}
</script>

<style>
.title-text{
    margin-top: 25px;
    text-align: center;
}
</style>
