import Swal from 'sweetalert2';

export const alertModal = ({title, text, confirmButtonText, cancelButtonText, acceptFunction, cancelFunction, showCancelButton, icon, onClose, }) => {
  Swal.fire({
    title: title,
    text: text,
    icon: icon,
    showCancelButton: showCancelButton,
    cancelButtonColor: '#d33',
    confirmButtonColor: '#1C76D2',
    cancelButtonText: cancelButtonText || 'Cancelar',
    confirmButtonText: confirmButtonText || 'Aceptar',
    willClose: onClose
  })
  .then(result => {
    if (result.value) {
      acceptFunction && acceptFunction();
    }
    else {
      cancelFunction && cancelFunction();
    }
  })
}

// export default alertModal;