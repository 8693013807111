
import {getStamentsService} from "../../services/stamentsServices";


export default {
    namespaced: true,
    state: {
        stament:{},
        stamentsEmit: "",
        stamentsState: [],
    },
    mutations: {
        SET_STAMENTSTATE (state, stament) {
            state.stamentsState = stament
        }        
    },
    actions: {
        async getStaments ({ commit },token){
            try {
                const data =  await getStamentsService(token); 
                commit('SET_STAMENTSTATE', data)   
            } catch (error) {
                console.log(error)
            }         
            
        },
        
    },
    getters:{

    }
}