<template>

  <v-container>
    <Complaints />
  </v-container>
 
</template>

<script>
import Complaints from '../components/Complaints/Complaints.vue'


export default {
  name: 'ComplaintsView',
  components: {
    Complaints
  },
}
</script>