import Vue from 'vue'
import VueRouter from 'vue-router'

// import Home from '../views/HomeView';
import LoginView from '../views/LoginView'
//import Menu from '../components/Menu/Menu'
import LogsView from '../views/LogsView'
import Profile from '../components/Profile/Profile'
import ComplaintsView from '../views/ComplaintsView'
//import { component } from 'vue/types/umd';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: ComplaintsView,
  },
  {
    path: '/Perfil',
    component: Profile,
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView
 },
 {
    path: '/Logs',
    name: 'LogsView',
    component: LogsView
  },
//  {
//     path: '/menu',
//     component: Menu,
//  },
 {
   path: '/complaints',
   name: 'complaintsView',
   component: ComplaintsView
 }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//(router.replace('/Perfil');

export default router;


