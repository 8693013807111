<template>
  <div>
      <Logs/>     
  </div>   
</template>

<script>
import Logs from '../components/Perfil Logs/Logs.vue'


export default {
  name: 'LogsView',
  components: {
      Logs
  },
}
</script>