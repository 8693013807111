import axios from "../axios/axios";

export const getcomplaintsService = async() => {
    const {data} = await axios.get("api/8K5D/complaints/");
    return data;
}


export const addComplaintsService = async(complaintData) => {
    const {data} = await axios.post("api/8K5D/complaints/", complaintData);
    return data;
}


export const getComplaintByInstitutionalEmail = async(id) => {
    const {data} = await axios.post("api/8K5D/complaints/byinstitutionalemail/", id);
    return data;
}
