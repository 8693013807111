
import {getLogService} from "../../services/logServices";


export default {
    namespaced: true,
    state: {
        log:{},
        logsState: [],
        logsEmit: "",
    },
    mutations: {
        SET_LOGSTATE (state, logs) {
            state.logsState = logs
        }        
    },
    actions: {
        async getLogs ({ commit },token){
            try {
                const data =  await getLogService(token); 
                commit('SET_LOGSTATE', data)   
            } catch (error) {
                console.log(error)
            }         
            
        },
        
    },
    getters:{

    }
}