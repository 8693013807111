import axios from "../axios/axios";


export const loginService = async(userCredentials) => {
    const {data} = await axios.post('api/8K5D/auth/login', userCredentials);
    return data;
};

export const logoutService = async() => {
    const {data} = await axios.post('api/8K5D/auth/logout');
    return data;
};