<template>
    <!-- Componente denuncia -->
    <v-container>
        <v-card>
            <br><br>
            <v-row>
                <v-col cols="12" sm="12" md="12" >
                    <v-textarea 
                        class="pa-2"
                        outlined 
                        name="input-7-4" 
                        label="Descripción de denuncia" 
                        v-model="details.eventDescription" 
                        required 
                        :rules="rules" 
                        :counter="500">
                    </v-textarea>
                </v-col>

                <v-col cols="12" sm="6" md="6"  >
                        <vs-checkbox 
                            class="pa-2"
                            warn 
                            v-model="details.protectiveMeasure" 
                            @click = "details.protectiveMeasureDescription=''"
                        >
                            Medidas de protección
                        </vs-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6"  ></v-col>

                <v-col  cols="12" sm="12" md="12" >
                    <v-textarea 
                        class="pa-2"
                        :disabled = !details.protectiveMeasure 
                        outlined 
                        name="input-7-4" 
                        label="Descripción medidas de protección" 
                        v-model="details.protectiveMeasureDescription" 
                        required 
                        :rules="rules" 
                        :counter="500">
                    </v-textarea>
                </v-col>
            </v-row>
            <br>
        </v-card>           
    </v-container>
</template>


<script>


export default {
    props:{
        staments: Array,
        units: Array,
        details: Object
    },
    data() {
        return{
            rules: [v => v.length <= 500 || 'Máximo 500 caracteres'],
        }     
    }
}
</script>
