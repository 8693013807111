<template>   
    <div class="ma-5">   
        <v-expansion-panels 
            focusable
            v-model="panel"
            multiple            
        >
            <v-expansion-panel
                v-for="(item,i) in competitors"
                :key="i"
            >
                <v-expansion-panel-header>
                    {{item.name}}
                    <!-- <template v-slot:actions color="red">
                        <v-btn
                            color="red"
                            icon                        
                            class="m1-2"
                            @click="removeItem(i)"
                        >
                            <v-icon color="red" >mdi-close</v-icon>                        
                        </v-btn>                        
                    </template> -->

                </v-expansion-panel-header>
                <v-expansion-panel-content>
                        <!-- <Complainant :staments="staments" :units="units" :complainant="competitors[i]" /> -->
                        <v-row>
                            <v-col cols="12" sm="6" md="6" >
                                <v-list two-line>
                                    <v-list-item>
                                        <v-list-item-icon>
                                        <v-icon color="indigo">
                                            mdi-card-account-details
                                        </v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                        <v-list-item-title>XX.XXX.XXX-X</v-list-item-title>
                                        <v-list-item-subtitle>RUT</v-list-item-subtitle>
                                        </v-list-item-content>                                       
                                    </v-list-item>
                                    
                                    <v-divider inset></v-divider>

                                    <v-list-item>
                                        <v-list-item-icon>
                                        <v-icon color="indigo">
                                            mdi-email
                                        </v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                        <v-list-item-title>{{item.personalEmail}}</v-list-item-title>
                                        <v-list-item-subtitle>Correo personal</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    

                                    <v-divider inset></v-divider>

                                    <v-list-item>
                                        <v-list-item-icon>
                                        <v-icon color="indigo">
                                            mdi-school
                                        </v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                        <v-list-item-title v-if="item.unitId">{{findUnit(item.unitId)}}</v-list-item-title>
                                        <v-list-item-subtitle>Unidad</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" >
                                <v-list two-line>
                                    <v-list-item>
                                        <v-list-item-icon>
                                        <v-icon color="indigo">
                                            mdi-rename-box
                                        </v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                        <v-list-item-title>{{item.name}}</v-list-item-title>
                                        <v-list-item-subtitle>Nombre</v-list-item-subtitle>
                                        </v-list-item-content>
                                        
                                    </v-list-item>                                    

                                    <v-divider inset></v-divider>

                                    <v-list-item>
                                        <v-list-item-icon>
                                        <v-icon color="indigo">
                                            mdi-email
                                        </v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                        <v-list-item-title>{{item.institutionalEmail}}</v-list-item-title>
                                        <v-list-item-subtitle>Correo institucional</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>                                    

                                    <v-divider inset></v-divider>

                                    <v-list-item>
                                        <v-list-item-icon>
                                        <v-icon color="indigo">
                                            mdi-account
                                        </v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                        <v-list-item-title v-if="item.estamentId">{{findStament(item.estamentId)}}</v-list-item-title>
                                        <v-list-item-subtitle>Estamento</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-row>
                        <v-btn
                            color="error"
                                      
                            class="m1-2"
                            @click="removeItem(i)"
                            :disabled = "disable"
                        >
                           Remover participante                      
                        </v-btn>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-snackbar
            v-model="snackbar"
            :multi-line="multiLine"
        >
            {{ text }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="red"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
    </div>    
</template>

<script>
import { mapState } from "vuex";

export default{
     props:{
        competitors: Array,
        disable: Boolean
    },
    data(){
        return{
            holi: true,
            multiLine: true,
            snackbar: false,
            text: `No puede dejar sin querellante`,
            panel: [],
        }
    },
    mounted(){
        console.log("Hola competitors es: ", this.competitors)
    },
    methods: {
        findUnit(id){
            return this.unitsState.find( unit => unit.id === id ).name;
        },
        findStament(id){
            return this.stamentsState.find( stament => stament.id === id ).type;
        },
        removeItem(index){
            if(this.competitors!=[]){
                console.log("Se va a eliminar el index=",index)
                this.competitors.splice(index,1)
            }
            else{
                console.log("   error Complaints está vacío")
            }   
        }
    },
    computed:{
        ...mapState('unit',['unitsState']),
        ...mapState('stament',['stamentsState']),
    },
    
}
</script>