<template>
    <div>
        <Validate v-on="handlers" :showValidate="showValidate" :complainant="complainant" :complainants="complainants"/>

        <v-form ref="form" v-model="valid" lazy-validation>
            <v-card class="mx-auto"
                max-width="1000">
                <v-row>                           
                    <v-col cols="12" sm="5" md="5" >  
                        <v-list-item>
                            <v-list-item-icon class="pa-2">
                                <v-icon color="indigo">
                                    mdi-account
                                </v-icon>
                            </v-list-item-icon>     
                        
                            <v-list-item-content>
                                <v-select
                                    label="Estamento del querellante"
                                    v-model="complainant.stament"
                                    :items="this.stamentsState"
                                    item-value="id"
                                    item-text="type"
                                    :rules="stamentRules"
                                    required
                                ></v-select>
                            </v-list-item-content>  
                        </v-list-item>                                 
                    </v-col>

                    <!-- <v-col cols="12" sm="6" md="6" >
                        <v-select
                            class="pa-2"
                            label="Unidad  del querellante"
                            v-model="complainant.unit"
                            :items="this.unitsState"
                            item-value="id"
                            item-text="name" 
                            :rules="unitRules"
                            required                       
                        ></v-select>
                    </v-col> -->

                    <v-col cols="12" sm="7" md="7" > 
                        <v-list-item>
                            <v-list-item-icon class="pa-2">
                                <v-icon color="indigo">
                                    mdi-email
                                </v-icon>
                            </v-list-item-icon>     
                        
                            <v-list-item-content>
                                <v-text-field
                                    class="pa-2"
                                    label="Correo institucional querellante"
                                    v-model="complainant.institutionalEmail"
                                    :rules="emailUsachRules"
                                    required
                                ></v-text-field>
                            </v-list-item-content>  
                        </v-list-item>                     
                    </v-col>

                    <v-col>
                        <v-btn
                            :loading="loading"
                            :disabled="!valid"
                            color="success"
                            class="mr-4"
                            @click="showValidateComplainant"
                        >
                        <v-icon color="white" left>mdi-account-check</v-icon>
                            Validar querellante
                        </v-btn>
                    </v-col>
                </v-row>
                <br>
            </v-card>
        </v-form>
        <v-snackbar
            v-model="snackbar"
            :timeout="2000"
            :value="true"            
            tile
            color="red accent-2"
            
        >
            {{ text }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>


<script>
import Validate from './ValidateComplainant.vue'
import { mapState } from "vuex";

export default {
    components:{
        Validate
    },
    props:{
        complainants: Array
    },
    data(){
        const vm = this;
        return{
            valid: true,
            loading: false,
            text: "Debe ser correo institucional!",
            snackbar: false,
            loader: null,
            stamentRules: [v => !!v || 'Debe seleccionar un estamento'],
            unitRules: [v => !!v || 'Debe seleccionar una unidad'],
            emailUsachRules: [
                v => !!v || 'Correo USACH es obligatorio',
                v => /.+@.+\..+/.test(v) || 'Correo ingresado inválido',
            ],
            complainant: {
                run:"", 
                name: "", 
                personalEmail: "", 
                institutionalEmail: "",
                stament: "", 
                unit: "", 
                type: 1, // tipo querellante
            },
            showValidate: false,
            handlers: {
                closeValidate: vm.closeValidateComplainant,
                saveAndCloseValidate: vm. cleanAndCloseValidate
                //closeAddV1: vm.closeAddComplaint
            }
        }
    },
    methods: { 
        showValidateComplainant(){
            // todos los campos deben estar para buscar y validar a querellante
            //this.$refs.form.validate();           
            // this.complainant.institutionalEmail = Q.institutionalEmail;
            // this.complainant.stament = Q.stament;
            this.loader = 'loading';
            const after_ = this.complainant.institutionalEmail.substring(this.complainant.institutionalEmail.indexOf('@') + 1);
            if(this.$refs.form.validate() && this.loader!=null){
                if(!after_.localeCompare("usach.cl")){
                    // querellante encontrado
                    let Q = {
                        run: "11111111-1",
                        name: "Juan Dip", 
                        personalEmail: "juan.dip@gmail.com", 
                        institutionalEmail: "juan.dip@usach.cl",
                        stament: "1", 
                        unit: "1", 
                    };
                    this.complainant.run = Q.run;
                    this.complainant.name = Q.name;
                    this.complainant.personalEmail = Q.personalEmail;

                    this.showValidate=true;
                }      
                else{
                    this.snackbar=true;
                }          
            }            
        },
        closeValidateComplainant(){
            this.showValidate=false;
        },
        cleanAndCloseValidate(){
            this.complainant.institutionalEmail="";
            this.complainant.stament=null;
            this.complainant.unit=null;
            this.$refs.form.reset()
            this.showValidate=false;
        }
    },
    computed:{
        ...mapState('unit',['unitsState']),
        ...mapState('stament',['stamentsState']),
    },
    watch: {
        loader () {
            const l = this.loader
            this[l] = !this[l]

            setTimeout(() => (this[l] = false), 3000)

            this.loader = null
        },
    }

}
</script>