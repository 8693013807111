<template>
    <div>
        <Validate v-on="handlers" :showValidate="showValidate" :denounced="denounced" :denounceds="denounceds"/>
        <Search v-on="handlers" :showSearch="showSearch" :denounced="denounced"/>

        <v-form ref="form" v-model="valid" lazy-validation>
            <v-card class="mx-auto"
                max-width="1000">
                <v-row>                           
                    <v-col cols="12" sm="5" md="5" >  
                        <v-list-item>
                            <v-list-item-icon class="pa-2">
                                <v-icon color="indigo">
                                    mdi-account
                                </v-icon>
                            </v-list-item-icon>     
                        
                            <v-list-item-content>
                                <v-select                                    
                                    label="Estamento del denunciado"
                                    v-model="denounced.stament"
                                    :items="this.stamentsState"
                                    item-value="id"
                                    item-text="type"
                                    :rules="stamentRules"
                                    required
                                ></v-select>
                            </v-list-item-content>  
                        </v-list-item>                                 
                    </v-col>

                    <v-col cols="12" sm="7" md="7" > 
                        <v-list-item>
                            <v-list-item-icon class="pa-2">
                                <v-icon color="indigo">
                                    mdi-email
                                </v-icon>
                            </v-list-item-icon>     
                        
                            <v-list-item-content>
                                <v-text-field
                                    class="pa-2"                                   
                                    label="Correo institucional denunciado"
                                    v-model="email"
                                ></v-text-field>
                            </v-list-item-content>  
                        </v-list-item>                     
                    </v-col>

                    <v-col>                        
                        <v-btn
                            :disabled="!valid"
                            color="primary"
                            class="mr-4"
                            v-show="searchByName"
                            @click="searchDenounced"
                        >
                        <v-icon color="white" left>mdi-magnify</v-icon>
                            Bucar denunciado por nombre
                        </v-btn>
                    
                        <v-btn
                            :disabled="!valid"
                            color="success"
                            class="mr-4"
                            v-show="!searchByName"
                            @click="showValidateDenounced"
                        >
                        <v-icon color="white" left>mdi-account-check</v-icon>
                            Validar denunciado
                        </v-btn>                        
                    </v-col>
                </v-row>
                <br>
            </v-card>
        </v-form>

        <v-snackbar
            v-model="snackbar"
            :timeout="2000"
            :value="true"            
            tile
            color="red accent-2"            
        >
            {{ text }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>


<script>
import Validate from './ValidateDenounced.vue'
import Search from './SearchDenouncedByName.vue'
import { mapState } from "vuex";

export default {
    components:{
        Validate,
        Search
    },
    props:{
        denounceds: Array,
    },
    data(){
        const vm = this;
        return{
            email:"",
            searchByName: true,
            text: "Debe ser correo institucional!",
            snackbar: false,
            valid: true,
            loading: false,
            loader: null,
            stamentRules: [v => !!v || 'Debe seleccionar un estamento'],
            emailUsachRules: [
                v => !!v || 'Correo USACH es obligatorio',
                v => /.+@.+\..+/.test(v) || 'Correo ingresado inválido',
            ],
            denounced: {
                run:"", 
                name: "", 
                personalEmail: "", 
                institutionalEmail: "",
                stament: "", 
                unit: "", 
                type: 2, // tipo denunciado
            },
            showValidate: false,
            showSearch: false,
            handlers: {
                closeValidate: vm.closeValidateDenounced,
                saveAndCloseValidate: vm. cleanAndCloseValidate,
                closeSearch: vm.closeSearchDenounced,
                selectedAndCloseSearch: vm.selectedAndCloseSearch
            }
        }
    },
    methods: {
        showButton(){
            if (this.denounced.institutionalEmail == ''){
                this.searchByName = true;
            }
            else{
                this.searchByName = false;
            }
        },
        searchDenounced(){
            if(this.$refs.form.validate()){
                this.showSearch = true;
            }            
        },
        showValidateDenounced(){
            
            // this.denounced.stament = Q.stament;

            this.loader = 'loading';
            const after_ = this.email.substring(this.email.indexOf('@') + 1);
            if(this.$refs.form.validate() && this.loader!=null){
                if(!after_.localeCompare("usach.cl")){
                    // Denunciado encontrado
                    let D = {
                        run: "11111111-1",
                        name: "Rodrigo Malote", 
                        personalEmail: "rodrigo.malote@gmail.com", 
                        institutionalEmail: "rodrigo.malote@usach.cl",
                        stament: "1", 
                        unit: "1", 
                    };
                    this.denounced.run = D.run;
                    this.denounced.name = D.name;
                    this.denounced.personalEmail = D.personalEmail;
                    this.denounced.institutionalEmail = this.email;

                    this.showValidate=true;
                }
                else{
                    this.snackbar=true;
                }
            }
            
        },
        closeValidateDenounced(){
            this.showValidate = false;
            this.showSearch = false;
        },
        cleanAndCloseValidate(){
            this.email = "";
            this.denounced.stament = null;
            this.denounced.unit = null;
            this.$refs.form.reset()
            this.showValidate = false;
            this.showSearch = false;
        },
        closeSearchDenounced(){
            this.email = "";
            this.denounced.stament = null;
            this.denounced.unit = null;
            this.$refs.form.reset()
            this.showSearch = false;
        },
        selectedAndCloseSearch(){
            this.showValidate = true;
        }
    },
    computed:{
        ...mapState('unit',['unitsState']),
        ...mapState('stament',['stamentsState']),
    },
    watch: {
        loader () {
            const l = this.loader
            this[l] = !this[l]

            setTimeout(() => (this[l] = false), 3000)

            this.loader = null
        },
        email(value){
            if (value == "" || value == null){
                this.searchByName = true;
            }
            else{
                this.searchByName = false;
            }
        }
    }

}
</script>