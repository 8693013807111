<template>
  <div id="app" v-if="isLogin">
    <!-- color="deep-purple accent-4" -->
    <v-app-bar app
      color="primary"
      dense
      dark
    >
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title>USACH</v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn> -->

      <v-menu
        left
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="i in options"
            :key="i"
            
            @click= "menuOptions(i - 1)"
          >
            <v-list-item-title
            class="d-flex justify-left">
              {{ optionName[i - 1] }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
//import {logoutService} from  "../../services/userServices"
import { mapActions, mapState, mapMutations} from "vuex";
import {parseJwt} from "./../functions/parseJwt"
export default {  
  name: 'Menu',
  data () { 
      const  vm = this;
      return{
         optionName: ["Perfil", "Logs", "Cerrar sesión"],
         functionName: [vm.profile, "userLogs", "logout()"],
        //  functionName: [
        //   vm.profile,
        //   vm.userLogs,
        //   vm.logout
        //  ],
         options:3,
      }
  },
  methods: {
    ...mapActions('user', ['logoutUser']),
    ...mapMutations('user', ['setUserState']),
    // parseJwt (token) {
    //   var base64Url = token.split('.')[1];
    //   var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    //   var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    //       return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    //   }).join(''));

    //   return JSON.parse(jsonPayload);
    // },
    async updateFromToken(){
      const data = parseJwt(localStorage.getItem('token'));
      const roles= [];
      console.log("las authorities son: ")
      for(var i=0 ; i< data.authorities.length; i++){
        console.log(data.authorities[i])
        roles.push({authority: data.authorities[i]})
      }

      const user = {
        user: {
          username: data.sub,
          authorities: roles,
        }
      } 
      try {
        await this.setUserState(user);
      } catch (error) {
        console.log(error)
      }
      
      console.log("Lo que tiene el token es: ", user );
    },
    async menuOptions(i){
      if(i===0){
          //console.log("Mostrando perfil")
          //this.$emit("showUserPerfil");
          const path = `/Perfil`
          if (this.$route.path !== path) this.$router.push(path)
          //this.$router.push('/');
          }
      else if(i===1){
          //console.log("Mostrando registros");
          //this.$emit("showUserLog");
          const path = `/Logs`
          if (this.$route.path !== path) this.$router.push(path)
          //this.$router.push('/Logs');
      }
      else if(i===2){
          console.log("Cerrando sesión");
          try {
            await this.logoutUser();
          } catch (error) {
            console.log(error)
          }          
          localStorage.clear();
          document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
          const path = `/login`
          if (this.$route.path !== path) this.$router.push(path)
          //this.$router.push('/login');
          //this.$emit("userLogout");
      }
      else{
        console.log("ERROR, opción incorrecta")
      }
        
    },
  },
  computed:{
      ...mapState('user', ['isLogin']),
  },
  async mounted (){
    console.log("isLogin: ",this.isLogin)
      if(!this.isLogin){
        if(localStorage.getItem('token')){
          await this.updateFromToken();          
        }
        else{
          console.log("No hay ningún token, redireccionando a login...");
          this.$router.push("/login");
        }
      }
  }
}
</script>

