import Vue from 'vue';
import Vuetify from 'vuetify';
import Vuesax from 'vuesax';
import 'vuesax/dist/vuesax.css';
import 'material-icons/iconfont/material-icons.css';


Vue.use(Vuetify);
Vue.use(Vuesax);

import es from 'vuetify/es5/locale/es'

Vue.component('my-component', {
    theme: {
        primary: '#7957d5',
    },
    methods: {
        changeLocale() {
            this.$vuetify.lang.current = 'es'
        },
    },
})

export default new Vuetify({
    lang: {
        locales: { es },
        current: 'es',
    },
})
