<template lang="html">
    <div class="center"> 
        
        <Detail v-model="showDetail" :complaintDetail="complaint"/> 
        <Add v-on="handlers"  :showAddV2="showAddV2"/>   

            <v-card>
                <v-card-title>
                    <h2>Historial de denuncias</h2>
                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                    ></v-divider>
                        
                    <v-btn 
                        @click="showAddV2=true" 
                        depressed 
                        color="primary" 
                        class="mx-2" 
                    >   
                        <v-icon dark>add</v-icon>
                    </v-btn>

                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        striped
                        append-icon="mdi-magnify"
                        label="Buscar"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table
                    class="row-pointer"
                    :headers="headers"
                    :items="complaints"
                    :search="search"
                    @click:row="handleClick"
                >
                    <template v-slot:item.protectiveMeasure="{ item }">
                    <v-chip
                        :color="getColor(item.protectiveMeasure)"
                        dark
                        
                    >
                        {{ item.protectiveMeasure }}
                    </v-chip>
                    </template>
                </v-data-table>
            </v-card>       
    </div>
</template>

<script>
import Detail from './Dialogs/DetailComplaint.vue';
import Add from './Dialogs/AddComplaint/AddComplaint.vue'
import {getcomplaintsService} from "../../services/complaintsServices"

export default {
    components:{
        Detail,
        Add
    },
    data() {
        const vm = this;
        return{
            complaints: [],
            headers: [
                {
                text: 'N° de causa',
                align: 'start',
                value: 'id',
                },
                { text: 'Descripción de denuncia', align: '',value: 'eventDescription' },
                { text: 'Tiene medidas de protección', align: 'start', value: 'protectiveMeasure' },
                { text: 'Fecha de denuncia', value: 'date' },
                { text: 'Última modificación', value: '---'}
            ],
            search: '',
            complaint: null,
            showDetail: false,
            showAddV2: false,
            handlers: {
                closeAdd: vm.closeAddComplaint,
                //closeAddV1: vm.closeAddComplaint
            },
        }
    },
    async mounted(){
        try {
            this.complaints = await getcomplaintsService();
        } catch (error) {
            console.log("getcomplaintsService: ", error)
        }     
    },
    methods:{
        getColor (op) {
            if (!op) return 'red'
            else return 'green'
        },
        handleClick(row) {
            this.complaint = row;
            console.log(this.complaint);
            this.showDetail = true;
            // this.showAdd = false;
        },
        async reloadComplaint(){
            console.log('Cerrar dialogo');
            // this.showDetail = false;
            // this.showAdd = false
            try {
                this.complaints = await getcomplaintsService();
            } catch (error) {
                console.log(error)
            }
        },   
        async closeAddComplaint(){
            this.showAddV2 = false;
            try {
                this.complaints = await getcomplaintsService();
            } catch (error) {
                console.log(error)
            }
        }
    },    
}
</script> 


<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>

<style>
.theme--light.v-data-table tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .03);
}
.theme--dark.v-data-table tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .5);
}
</style>