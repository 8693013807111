<template>
  <div class="center" rigth margin-rigth:20px centering>
      <Login/>
      
  </div>
  
    
</template>

<script>
import Login from '../components/Login/Login.vue'


export default {
  name: 'LoginView',
  components: {
      Login
  },
}
</script>