import axios from 'axios';
import baseUrl from './urls';

// let token = '';

const clientAxios = axios.create({
  baseURL: `${baseUrl}`,
  headers: { 'Content-Type': 'application/json'},
  mode: 'no-cors'
});

const requestHandler = request => {
  // Token will be dynamic so we can use any app-specific way to always
  // fetch the new token before making the call
  const cookies = document.cookie.replace(/ /g,"").split(';');
  let data = {};
  for (var i = 0; i < cookies.length; i++) {
    const cookieData = cookies[i].split('=');
    data[cookieData[0]] = cookieData[1];
  }
  console.log(data);
  request.headers.Authorization = 'Bearer ' + data.token;
  console.log(request);

  return request;
};

const errorHandler = error => {
    console.log(error);
    return Promise.reject(error);
};

clientAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

clientAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalConfig = error.config;
    console.log(originalConfig);
    if (error.response) {
      if (error.response.status === 401) {
        // Do something, call refreshToken() request for example;
        // return a request
        // return clientAxios(originalConfig);
        const isAuthorized = await window.UsachDTISecured.isAuthorized();
        if (isAuthorized) {
          return Promise.reject(error.response.data);
        }
        else {
          const responseRefresh = await window.UsachDTISecured.newToken();
          if (responseRefresh) {
            return clientAxios(originalConfig);
          }
          else {
            return Promise.reject(error.response.data);
          }
        }

      }

      else {
        return Promise.reject(error.response.data);
      }

    }

    return Promise.reject(error);
  }
);


export default clientAxios;
