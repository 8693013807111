
import {getUnitsService} from "../../services/unitsServices";


export default {
    namespaced: true,
    state: {
        unit:{},
        unitsEmit: "",
        unitsState: [],
    },
    mutations: {
        SET_UNITSTATE (state, units) {
            state.unitsState = units
        }        
    },
    actions: {
        async getUnits ({ commit },token){
            try {
                const data =  await getUnitsService(token);                
                commit('SET_UNITSTATE', data)   
            } catch (error) {
                console.log(error)
            }         
            
        },
        
    },
    getters:{

    }
}