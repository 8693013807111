<template>   
    

    <v-container fluid style = "max-width: 800px">

       
        <v-card class="event-card" justify-center >
        <v-layout row>
        <img :src="pic">
        <v-layout column justify-space-between style="padding: 0.8em 1.3em; max-width: 390px;">
            <div >
              <h1 class="name">{{ title }}</h1>
              <h3 class="date">{{ date }}</h3>
              <!-- <br>
              <h3 >Roles</h3> -->
              <v-chip v-for="h in userRoles" :key="h.authority" dark class="ml-3" color="deep-purple lighten-1">{{h.authority}}</v-chip>
            </div>
            <div>             
              <p class="desc">{{ desc }}</p>              
            </div>
            <div class="date-ribbon">
              <h2>{{ month }}</h2>
              <h1>{{ day }}</h1>
            </div> 
        </v-layout>
        </v-layout>
        </v-card>


        
    </v-container>




</template>

<script>
import {mapState} from 'vuex'
export default {
  name: "Profile",
  data () {
    //const vm = this;
    return{
        title: '',
        date: 'August 28 | 8am - 3pm',
        pic: 'https://images.unsplash.com/photo-1484507175567-a114f764f78b?ixlib=rb-0.3.5&s=abc2cb4d7e6d8aca1e8914c1b5e909a6&auto=format&fit=crop&w=500&q=60',
        month: '',
        day: '',
        userRoles: [],
        desc: ''
    }
  },
  async mounted() {
      if(localStorage.getItem('token')){      
        try {  
          this.title = await "Bienvenido "+this.userState.user.username.split('@')[0];
          const today = new Date()
          this.desc =  "Correo: "+this.userState.user.username
          this.month = today.toLocaleString('default', { month: 'short' });
          this.day = today.getDate()
          const month = today.toLocaleString('it-IT', { month: 'long' }) ;
          this.date = today.getDate() + " de "+ month.charAt(0).toUpperCase() + month.slice(1)        
          this.userRoles = await this.userState.user.authorities;
        } catch (error) {
          console.log(error)
        }        
        console.log("Los roles son: ",this.userRoles)
        // for (var i = 0; i < roles.length; i++){
        //   this.userRoles.push(roles[i]);
        // }
      }          
  },
  computed:{
    ...mapState('user',['userState']),
    ...mapState('user', ['isLogin']),     
  },
}
</script>

<style scoped>
/* Event Card List CSS */
.event-card-list {
  margin-top: 4em;
}

.event-card-list li {
  list-style: none;
  margin: 2em 0;
}

.event-card {
  overflow: hidden;
  width: 100%;

  border-radius: 0.3em;
}

.event-card img {
  width: 300px;
  height: 300px;

  object-fit: cover;
}

.event-card .name {
  font-size: 2.3em;
  font-weight: 400;
}

.event-card .name a {
  text-decoration: none;
  /*color: #212121;*/
}

.event-card .date {
  font-size: 1.4em;
  font-weight: 400;
  color: #6D6D6D;
}

.event-card .location {
  font-size: 1em;
  color: #757575;
}

.event-card .location i {
  font-size: 1.1em;
  padding-right: 0.3em;
  margin-bottom: 0.085em;
}

.event-card .desc {
  margin-bottom: 0.2em;
  font-size: 1.16em;
  padding-left: 0.1em;
}

.event-card .date-ribbon {
  position: absolute;
  top: 0;
  left: 2em;
  background: #FE453E;
  color: #fff;
  padding: 0.2em 1em;
  padding-bottom: 0;
  border-radius: 0;
}

.event-card .date-ribbon::before, .event-card .date-ribbon::after {
  content: '';
  position: absolute;
  top: 100%;
  
  width: 50%;
  height: 30px;
}

.event-card .date-ribbon::before {
  left: 0;
  border-left:solid 2em #FE453E;
  border-top: solid 15px #FE453E;
  border-bottom: solid 15px transparent;
  border-right: solid 2em transparent;
  }

.event-card .date-ribbon::after {
  right: 0;
  border-right:solid 2em #FE453E;
  border-top: solid 15px #FE453E;
  border-bottom: solid 15px transparent;
  border-left: solid 2em transparent;
}

.event-card .date-ribbon h2 {
  font-weight: 500;
  font-size: 1.15em;
  letter-spacing: 0.07em;
  text-align: center;
}

.event-card .date-ribbon h1 {
  text-align: center;
  font-weight: 400;
  font-size: 2.45em;
  margin-top: -0.09em;
  line-height: 1em;
}
</style>