import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import store from './store/store';
import VueSweetalert2 from 'vue-sweetalert2';

import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'sweetalert2/dist/sweetalert2.min.css';


Vue.config.productionTip = false;
Vue.use(VueSweetalert2);

/* eslint-disable no-new */
new Vue({
   router: router,
   vuetify,
   store,
   render: h => h(App),
}).$mount('#app')




// new Vue({
//   router,
//   vuetify,
//   render: h => h(App),
// }).$mount('#app')

