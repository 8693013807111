import Vue from 'vue';
import Vuex from 'vuex';

import logs from './modules/logModule';
import user from './modules/userModule'
import unit from './modules/unitModule'
import stament from './modules/stamentModule'

Vue.use(Vuex);

export default new Vuex.Store({   
    state: {

    },
    mutations: {

    },
    actions: {

    },
    modules:{
        logs,user,unit,stament
    }
});