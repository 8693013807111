<template>
    <div>
        <v-list-item>
            <template>
            <!-- <template v-slot:default="{ active }"> -->
            <v-list-item-content>

                <v-row>
                              <v-col cols="12" sm="6" md="6">
                                    <v-list-item-title v-text="`${index} - ${log.user_email}`"></v-list-item-title>
                              </v-col>
                              <v-col cols="12" sm="6" md="6">
                                    <v-list-item-subtitle
                                    class="text--primary"
                                    v-text="log.action"
                                    ></v-list-item-subtitle>

                                    <v-list-item-subtitle v-text="log.createdAt"></v-list-item-subtitle> 
                              </v-col>
                </v-row>
                
            </v-list-item-content>
            </template>
        </v-list-item>

        <v-divider
        v-if=" index !== -1"
        ></v-divider> 
    </div>
</template>


<script>
  export default {    
    props: {
        log: Object,
        index: Number,
    }
}
</script>