<template>
  <v-app id="app">
    <AppBar/>
    <Layout/>
  </v-app>
</template>

<script>

import Layout from './Layout/Layout';
import AppBar from './Layout/AppBar.vue'

export default {
  name: 'App',
  components: {
    Layout,
    AppBar
  },
  created() {

  },
  methods: {

  }
}
</script>

<style>
#app { 
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  
}
</style>
