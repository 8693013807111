<template >
   
   <v-container fill-height fluid >
      <v-layout align-center justify-center>
         <v-flex xs12 sm8 md4 style="max-width: 500px;">
            <v-card elevation="12" >
               <v-toolbar dark color="primary">
                  <v-toolbar-title>Login</v-toolbar-title>
               </v-toolbar>

               <v-tabs v-model="tab" color="primary">
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab v-for="i in tabs" :key="i" :href="`#tab-${i}`" @click="selectTab(i)">
                        {{ tabsName[i - 1] }}
                  </v-tab>
               </v-tabs>

               <v-card-text v-show="selectedTab == 1">
                  <v-form
                     ref= "rloginForm"
                    
                     lazy-validation>
                     <v-row>
                        <v-col cols="12">
                              <v-text-field
                                 prepend-icon="person"
                                 @click="loginerror=true"
                                 name="login"
                                 label="Cuenta"
                                 type="text"
                                 v-model="loginUsername"
                                 :rules="[rules.required, loginerror]"
                                 v-on:keyup.enter="onEnter"
                              ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                              <v-text-field
                                    block
                                    @click="loginerror=true"
                                    prepend-icon="lock"
                                    name="password"
                                    label="Contraseña"
                                    v-model="loginPassword"
                                    :rules="[rules.required]"
                                    :append-icon="show0 ? 'mdi-eye' : 'mdi-eye-off'"  :type="show0 ? 'text' : 'password'" counter @click:append="show0 = !show0"
                                    v-on:keyup.enter="onEnter"
                                 >
                              </v-text-field> 
                        </v-col>
                        <v-spacer></v-spacer>                                    
                     </v-row>
                     
                  </v-form>                  
               </v-card-text>
               <v-card-actions v-show="selectedTab == 1">
                  <v-spacer></v-spacer>
                  <!-- to = "/" -->
                  <v-btn color="primary" @click="checkform()" >Entrar</v-btn>
               </v-card-actions>

               <v-card-text v-show="selectedTab == 2">                        
                  <v-form ref="registerForm" v-model="valid" lazy-validation>
                           <v-row>
                              <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="firstName" :rules="[rules.required]" label="Nombre" maxlength="20" required></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="lastName" :rules="[rules.required]" label="Apellido" maxlength="20" required></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                    <v-text-field v-model="streetAddress" :rules="[rules.required]" label="Dirección" required></v-text-field>
                              </v-col>   
                              <v-col cols="6">
                                    <v-text-field v-model="city" :rules="[rules.required]" label="Ciudad" required></v-text-field>
                              </v-col>                                 
                              <v-col cols="6">
                                    <v-text-field v-model="comuna" :rules="[rules.required]" label="Comuna" required></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                    <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                    <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Contraseña" hint="At least 8 characters" counter @click:append="show1 = !show1"></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                    <v-text-field block v-model="cpassword" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, passwordMatch]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Confirmar Contraseña" counter @click:append="show1 = !show1"></v-text-field>
                              </v-col>                                    
                              <v-spacer></v-spacer>                                    
                           </v-row>
                        </v-form>
               </v-card-text>
               <v-card-actions v-show="selectedTab == 2">
                  <v-spacer></v-spacer>
                  <v-btn color="primary" to="/">Registrar</v-btn>
               </v-card-actions>

            </v-card>
         </v-flex>
      </v-layout>
   </v-container>
</template>

<script>
import {mapActions} from 'vuex'

export default {
   name: 'Login',
   props: {
      source: String,
   },
   data () { 
      
      return{
         tabsName: ["Iniciar sesión", "Registrarse"],
         tabs: 2,
         tab: null,
         selectedTab: 1,
         valid: true,
         loginForm: true,
         loginerror: true,
         firstName: "",
         lastName: "",
         email: "",
         city: "",
         comuna: "",
         streetAddress: "",
         cpassword: "",
         loginUsername: "",
         loginPassword: "",
         loginData: "",
         loginEmail: "",

         // loginEmailRules: [
         //    v => !!v || "Required",
         //    v => /.+@.+\..+/.test(v) || "E-mail debe ser válido"
         // ],
         emailRules: [
            v => !!v || "Requerido",
            v => /.+@.+\..+/.test(v) || "E-mail debe ser válido"
         ],
         show1: false,
         show0:false,
         password: "",
         rules: {
            required: value => !!value || "Requerido.",
            min: v => (v && v.length >= 8) || "Min 8 caracteres"
         }
      }    
   },
   methods: {
   ...mapActions('user', ['loginUser']),
   
   onEnter: function() {
      this.checkform();
   },
   selectTab(stuff) {
      this.selectedTab = stuff;
   },
   checkform(){   
      //console.log("El resultado de la validacion del form es: ",this.$refs.rloginForm.validate())      
      if (this.$refs.rloginForm.validate())
      {
         this.submitUser();
      } 
   },    
   async submitUser(){
      localStorage.clear();
      document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });

      const udata = {
         username: this.loginUsername,
         password: this.loginPassword,
      };
      try {
         this.loginData = await this.loginUser(udata);
      } catch (error) {
         console.log("El error es:",error)
      }
      console.log("lo obteniso es: ",this.loginData)
      if (this.loginData.token){
         console.log("el token es valido")
         this.badCredentials = false;
         localStorage.setItem('token',this.loginData.token)
         document.cookie = 'token='+this.loginData.token
         this.$router.push('/');
         return true;
      }
      else{
         console.log("Hubo un error en la autenticación")
         this.loginerror= "La cuenta o contraseña ingresada no son válidas";
         return () => "La cuenta o contraseña ingresada no son válidas";
      } 
    },   
   },
   computed: {
      passwordMatch() {
         return () => this.password === this.cpassword || "Las contraseñas deben ser iguales";
      }    
   }
};
</script>

