<template>    
    <v-dialog 
        v-model="showSearch" 
        max-width="1000px" 
        persistent
    >
        <div class="con-content">
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card class="mx-auto">
                    <br>
                    <h3 class="text-h5 text-center">
                        <b>Buscar denunciado por nombre</b>
                    </h3>                                

                    <v-card-text>           
                        <br>
                        <v-row>
                            <v-col cols="12" sm="12" md="12" >
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Nombre denunciado"
                                    single-line
                                    hide-details
                                ></v-text-field>
                            </v-col>
                        </v-row> 
                        <br><br> 
                        <v-data-table
                            v-model="selected"
                            single-select
                            :search="search"
                            :headers="headers"
                            :items="desserts"
                            item-key="name"
                            show-select
                            checkbox-color = "primary"
                            class="elevation-1"
                        ></v-data-table>
                        <br>
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-row>
                            <v-col cols="12" sm="6" md="6" >
                                <v-btn
                                    color="error"
                                    class="mr-4"
                                    @click="closeSearch"
                                    align="left"
                                >
                                    Cancelar
                                </v-btn>
                            </v-col>
                            
                            <v-col cols="12" sm="6" md="6" >
                                <v-btn
                                    color="success"
                                    class="mr-4"
                                    @click="selectedDenounced"
                                    align="right"
                                >
                                <v-icon color="white" left>mdi-account-check</v-icon>
                                    Seleccionar
                                </v-btn>
                            </v-col>                       
                        </v-row>                        
                    </v-card-actions>
                </v-card>                   
            </v-form>

            <v-snackbar
                v-model="snackbar"
                :timeout="2000"
                :value="true"            
                tile
                color="red accent-2"            
            >
                {{ text }}
                <template v-slot:action="{ attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                    >
                        Cerrar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>    
    </v-dialog>
</template>



<script>
export default {
    props:{
        showSearch: Boolean,
        denounced: Object
    },
    data() {
        return{ 
            valid: true, 
            text: "Debe seleccionar a un denunciado",
            snackbar: false, 
            search: '',  
            selected: [],
            headers: [
                {
                    text: 'Nombre participante',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                //{ text: 'Estamento', value: 'calories' },
                { text: 'Unidad', value: 'fat' },
                { text: 'Correo Institucional', value: 'correoInstitucional' },
                { text: 'Correo personal', value: 'correo' },
            ],
            desserts: [
                {
                    name: 'Pedro Perez',
                    calories: 'Estudiante',
                    fat: 'Departamento de Informática',
                    correoInstitucional: "pedro.p@usach.cl",
                    correo: "pp@gmail.com",
                },
                {
                    name: 'Cecilia Arjona',
                    calories: 'Estudiante',
                    fat: 'Departamento de Humanidades',
                    correoInstitucional: "cecilia.a@usach.cl",
                    correo: "the.amazing.c@hotmail.com",
                },
                {
                    name: 'Roberto Tapia',
                    calories: 'Estudiante',
                    fat: 'Departamento de Matemáticas',
                    correoInstitucional: "roberto.t@usach.cl",
                    correo: "rodelcaze@hotmail.com",
                },
                {
                    name: 'Ximena Corona',
                    calories: 'Académico',
                    fat: 'Departamento de Matemáticas',
                    correoInstitucional: "Ximena.c@usach.cl",
                    correo: "ximenc@gmail.com",
                },
                {
                    name: 'Camila González',
                    calories: 'Funcionario',
                    fat: 'Departamento de Biología',
                    correoInstitucional: "camila.g@usach.cl",
                    correo: "camila.g@gmail.com",
                },
                {
                    name: 'Sofia Powers',
                    calories: 'Estudiante',
                    fat: 'Departamento de Matemáticas',
                    correoInstitucional: "sofia.p@usach.cl",
                    correo: "soysofi@gmail.com",
                },
                {
                    name: 'Mónica Casas',
                    calories: 'Estudiante',
                    fat: 'Departamento de Humanidades',
                    correoInstitucional: "monica.c@usach.cl",
                    correo: "moniiixx@hotmail.com",
                },
                {
                    name: 'Manuel Acevedo',
                    calories: 'Académico',
                    fat: 'Departamento de Informática',
                    correoInstitucional: "manuel.a@usach.cl",
                    correo: "manuel.acev@gmail.com",
                },
                {
                    name: 'Matías Morales',
                    calories: 'Funcionario',
                    fat: 'Departamento de Humanidades',
                    correoInstitucional: "matias.m@usach.cl",
                    correo: "matim@gmail.com",
                },
                {
                    name: 'Benjamin Soto',
                    calories: 'Estudiante',
                    fat: 'Humanidades',
                    correoInstitucional: "benjamin.s@usach.cl",
                    correo: "benjita@gmail.com",
                },
            ],
        }
    },
    methods: {              
        remove (item) {
            this.selected.splice(item.name, 1)
            this.selected = [...this.selected]
        },
        closeSearch(){
            this.selected = [];
            this.$emit('closeSearch');
        },
        selectedDenounced(){
            if(this.selected.length != 0){
                console.log("asd")
                this.denounced.name = this.selected[0].name;
                this.denounced.personalEmail = this.selected[0].correo;
                this.denounced.institutionalEmail = this.selected[0].correoInstitucional;

                this.$emit('selectedAndCloseSearch')
                this.selected = [];
            }
            else{
                this.snackbar=true;
            }
        }
    }
}
</script>

