<template>
  <v-card
    class="mx-auto"
    max-width="1000"
  >


      <v-list two-line style="max-height: 1000px"
       class="overflow-y-auto">
        <v-list-item-group
          v-model="selected"
          active-class="pink--text"
          disabled
        >
          <template v-for="(logu, index) in this.userLogs">
            <Log :log='logu' :index='index' :key="logu.id" /> 
          </template>
        </v-list-item-group>
      </v-list>
 

  </v-card>
</template>


<script>
  // import { mapState} from "vuex";
  import {getLogService} from "../../services/logServices"
  import Log from "./Log.vue"

  export default {
    components:{
      Log,
    },
    data () {
        return{
            selected: [2],
            userLog: '',   
            userLogs:[],         
        }
    },
    async mounted(){
      try {
        this.userLogs = await getLogService();
      } catch (error) {
        console.log(error)
      }     
    }
}
</script>